import React from 'react'
import Layout from "../components/layout"
import Helmet from 'react-helmet'
import Header from '../components/header'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Smile from '../images/smile.svg'
import Smilesmall from '../images/smile-small.svg'
import CirclesBg from '../images/circles-bg.svg'
import RecordIcon from '../images/record-video-icon.svg'
import GroupIcon from '../images/group-icon.svg'
import ShareIcon from '../images/share-video-icon.svg'
import BackgroundBoy from '../components/bgimage'
import BackgroundSisters from '../components/bgimagesisters'
import BackgroundGrandpa from '../components/bgimagegrandpa'
import Cards from '../components/cards'
import Footer from '../components/footer'

export default ({ data }) => (

  <Layout>
    <Helmet>
      <title>Joyful virtual birthday cards for Covid lockdown and quarantine - Joycards</title>
      <meta name="description" content="Create an online birthday card, out of videos, for your friend or family member. Joycards is a group video card that helps you share more joy during Covid lockdown." />
    </Helmet>
    <Header />
    <section id='hero' role="main">
      <div className='container two-grid'>
        <div className='hero-left hero-text flex flex-col flex-jc'>
          <h1>
              Create joyful lockdown<br /><span className='sexy-text'>birthday cards</span><br />
          </h1>
          <p className='lead mt-0'>Joycards are group video birthday cards. Collect video messages from family and friends into one memorable, lasting birthday video card that the recipient will love.</p>
          <div className='cta-btn-wrapper'>
            <a href="https://app.joy.cards/accounts/signup/" target="_blank" rel="noopener noreferrer" className='btn btn-teal btn-lg cta-btn'>Start your card</a>
            <div className='hero-below-btn'>
              <span>It's free. No credit card required.</span>
            </div>
          </div>
        </div>
        <div className='hero-right'>
          <div className='hero-img-wrapper'>
            <div className='bg-circles-top'>
              <img src={CirclesBg} alt='circles for decoration' aria-hidden="true"/>
            </div>
            <div className='hero-img'>
              <Img fluid={data.file.childImageSharp.fluid}
              alt="group video card showing multiple people making videos for a birthday card" />
            </div>
            <div className='smile-img'>
              <img src={Smile} alt='smile icon' aria-hidden="true"/>
            </div>
            <div className='bg-circles-side'>
              <img src={CirclesBg} alt='circles for decoration' aria-hidden="true"/>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='beige-bg section-wrapper-lg'>
      <div className='container'>
        <div className='highlight-wrapper'>
          <h2 className='text-center'>Create a birthday card in 3 easy steps</h2>
          <p className='text-center lead'>Birthdays are big deals. Especially during Covid19 and quarantine. It's important to spread joy and make people feel special during the Corona virus lockdowns. Joycards makes it simple to create a group video card that is more memorable and lasts forever.</p>
          <div className='flex flex-jc flex-wrap mt-2 text-center'>
            <div className='highlight'>
              <div className='highlight-head'>
                <img src={RecordIcon} alt='record video icon' aria-hidden="true"/>
              </div>
              <div className='highlight-body'>
                <h4 className='mt-1'>Record your video</h4>
                <p className='lead mt-05'>Record your own personal video message.</p>
              </div>
            </div>
            <div className='highlight'>
              <div className='highlight-head'>
                <img src={GroupIcon} alt='group of people icon' aria-hidden="true"/>
              </div>
              <div className='highlight-body'>
                <h4 className='mt-1'>Invite family &amp; friends</h4>
                <p className='lead mt-05'>Send invites to family and friends of the birthday boy/girl so they can contribute to your group card.</p>
              </div>
            </div>
            <div className='highlight'>
              <div className='highlight-head'>
                <img src={ShareIcon} alt='share video icon' aria-hidden="true"/>
              </div>
              <div className='highlight-body'>
                <h4 className='mt-1'>Share the joy</h4>
                <p className='lead mt-05'>When your card is ready, send it to the recipient and share some joy.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='beige-bg feature-wrapper'>
      <div className='container'>
        <div className='two-grid-features feature-box'>
          <div className='feature-img'>
            <BackgroundBoy />
          </div>
          <div className='feature-content flex flex-jc flex-ac flex-col'>
            <img src={Smilesmall} alt='smile icon' aria-hidden="true"/>
            <div className='feature-text'>
              <h3>One video card,<br />many videos</h3>
              <p>Create more memorable, and lasting, virtual birthday cards that people love.</p>
            </div>
            <a href="https://app.joy.cards/accounts/signup/" target="_blank" rel="noopener noreferrer" className='feature-link'>Start your birthday card</a>
          </div>
        </div>

        <div className='two-grid-features grid-reversed feature-box feature-mid'>
          <div className='push'>
            <div className='feature-content flex flex-jc flex-ac flex-col'>
              <img src={Smilesmall} alt='smile icon' aria-hidden="true"/>
              <div className='feature-text'>
                <h3>Invite<br /> family and friends</h3>
                <p>Invite family and friends to contribute their video message to make a lasting digital greeting card that will be cherished forever.</p>
              </div>
              <a href="https://app.joy.cards/accounts/signup/" target="_blank" rel="noopener noreferrer" className='feature-link'>Start your card</a>
            </div>
          </div>
          <div className='pull feature-img'>
            <BackgroundSisters />
          </div>
        </div>

        <div className='two-grid-features feature-box'>
          <div className='feature-img'>
            <BackgroundGrandpa />
          </div>
          <div className='feature-content flex flex-jc flex-ac flex-col'>
            <img src={Smilesmall} alt='smile icon' aria-hidden="true"/>
            <div className='feature-text'>
              <h3>More joy than a greeting card</h3>
              <p>Joycards last forever. They're more personal, and more loved, than traditional paper greeting cards and birthday eCards.</p>
            </div>
            <a href="https://app.joy.cards/accounts/signup/" target="_blank" rel="noopener noreferrer" className='feature-link'>Start your card</a>
          </div>
        </div>
      </div>
    </section>

    <section className='beige-bg section-wrapper-sm'>
      <div className='container'>
        <div className='sub-page-content'>
          <div className='qa'>
            <h5>What are group video cards?</h5>
            <p>Joycards enables you to collate video messages from family and friends to create a digital birthday card, containing many video messages.</p>
            <p>It's not just for birthdays! You can use it for all types of <Link to="/birthday-card">birthday cards</Link>, farewell cards, get-well-soon cards, wedding cards and all other times you want to send a card that is more loved, and lasts longer, than a boring old greeting card or eCard.</p>
          </div>
          <div className='qa'>
            <h5>Are Joycards only for Covid and lockdown periods?</h5>
            <p>Definitely NOT! Joycards can be used at any time and for almost any occassion. Our objective is to improve the greeting card experience and bring it online even in in years where we don't experience lockdowns or isolation periods.</p>
          </div>
          <div className='qa'>
            <h5>How does it work?</h5>
            <p><Link to="/how-to-1">Sign up for free</Link> and record your video. Then invite people to add their videos to the card. When you've received all the videos simply send your Joycard to the person whose birthday it is.</p>
          </div>
          <div className='qa'>
            <h5>Is it free?</h5>
            <p>Joycards is currently free to use. We will add new paid features in future but for now enjoy sending more joy for free <span role="img" aria-label="smiley face">😄</span>.</p>
          </div>
          <div className='qa'>
            <h5>How many people can contribute to my birthday card?</h5>
            <p>You can have up to 50 people contribute a video to your Joycard.</p>
          </div>
          <div className='qa'>
            <h5>How long are the videos?</h5>
            <p>The maximum length of a video is 1 minute. However, we find videos between 15 - 30 seconds work best as part of a group video card.</p>
          </div>
          <div className='qa'>
            <h5>Can I save my videos?</h5>
            <p>Your videos will always be available in your account. Plus, if the recipient of your Joycard chooses to open an account they will have access to their Joycard whenever they want to view it.</p>
          </div>
          <div className='qa'>
            <h5>It's my birthday. Can I create a Joycard?</h5>
            <p>You sure can.</p>
            <p>Create a card and have all your family and friends contribute a video message to create a keepsake you'll cherish forever.</p>
          </div>
          <div className='qa'>
            <h5>Is this a birthday eCard?</h5>
            <p>No! Joycards are different to eCards and traditional paper greeting cards.</p>
            <p>Joycards capture video messages from multiple people allowing the recipient to keep the group videos and watch them again and again in years to come. It's more personal, more fun and much more memorable.</p>
          </div>
        </div>
      </div>
    </section>

    <section className='cta'>
      <div className='container text-center'>
        <div className='flex flex-col'>
          <h5>Share some joy</h5>
          <div className='cta-btn-wrapper mt-1'>
            <a href="https://app.joy.cards/accounts/signup/" target="_blank" rel="noopener noreferrer" className='btn btn-teal btn-lg cta-btn'>Start Your Card - Free</a>
          </div>
        </div>
      </div>
    </section>
    <Cards />
    <Footer />
  </Layout>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "group-video-card.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800 quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
